import { Permission } from "../types/Permission";
import { Role } from "../types/Role";

/**
 * Maps permissions to roles that have that permission.
 * @ignore
 */
export const PERMISSION_ROLES: Record<Permission, Role[]> = {
  CAN_SEE_ADMIN_PANEL: ["USER_ADMIN"],
  CAN_READ_LOGS: ["USER_ADMIN"],
  CAN_WRITE_AUTH_USER: ["AUTH_USER"],
  CAN_WRITE_USER: ["USER_ADMIN", "AUTH_USER"],
  CAN_WRITE_USER_ROLE: ["USER_ADMIN"],
  CAN_ASSUME_ORGANIZATION: [
    "USER_ADMIN",
    "MEMBER_OWNER",
    "MEMBER_MANAGER",
    "MEMBER_REGULAR",
  ],
  CAN_WRITE_ORGANIZATION: ["USER_ADMIN", "MEMBER_OWNER", "MEMBER_MANAGER"],
  CAN_DELETE_ORGANIZATION: ["USER_ADMIN"],
  CAN_WRITE_ORGANIZATION_MEMBER: [
    "USER_ADMIN",
    "MEMBER_OWNER",
    "MEMBER_MANAGER",
  ],
  CAN_WRITE_ORGANIZATION_CONNECTOR_CONFIG: [
    "USER_ADMIN",
    "MEMBER_OWNER",
    "MEMBER_MANAGER",
  ],
  CAN_CREATE_PROJECT: ["USER_ADMIN"],
  CAN_UPDATE_PROJECT_ALL: ["USER_ADMIN"],
  CAN_UPDATE_PROJECT_LIMITED: ["USER_ADMIN", "MEMBER_OWNER", "MEMBER_MANAGER"],
  CAN_UPDATE_PROJECT_ISSUE_CATEGORIES: [
    "USER_ADMIN",
    "MEMBER_OWNER",
    "MEMBER_MANAGER",
  ],
  CAN_UPDATE_PROJECT_CALL_CATEGORIES: [
    "USER_ADMIN",
    "MEMBER_OWNER",
    "MEMBER_MANAGER",
  ],
  CAN_UPDATE_PROJECT_SCORECARD_QUESTIONS: [
    "USER_ADMIN",
    "MEMBER_OWNER",
    "MEMBER_MANAGER",
  ],
  CAN_ARCHIVE_PROJECT: ["USER_ADMIN", "MEMBER_OWNER", "MEMBER_MANAGER"],
  CAN_DELETE_PROJECT: ["USER_ADMIN", "MEMBER_OWNER", "MEMBER_MANAGER"],
  CAN_RESTORE_PROJECT: ["USER_ADMIN"],
  CAN_WRITE_PROJECT_CALL_FLAGGING_CONFIGS: [
    "USER_ADMIN",
    "MEMBER_OWNER",
    "MEMBER_MANAGER",
  ],
  CAN_WRITE_PUBLISHED_TENDER: ["USER_ADMIN"],
  CAN_WRITE_TENDER: [
    "USER_ADMIN",
    "MEMBER_OWNER",
    "MEMBER_MANAGER",
    "MEMBER_REGULAR",
  ],
  CAN_PUBLISH_TENDER: ["USER_ADMIN"],
  CAN_DELETE_TENDER: ["USER_ADMIN"],
  CAN_END_TENDER: ["USER_ADMIN", "MEMBER_OWNER", "MEMBER_MANAGER"],
  CAN_RESTORE_TENDER: ["USER_ADMIN"],
  CAN_UPDATE_TENDER_STATUS: ["USER_ADMIN"],
  CAN_UPDATE_TENDER_PROVIDERS: ["USER_ADMIN"],
  CAN_ASSIGN_CALL: [
    "USER_ADMIN",
    "MEMBER_OWNER",
    "MEMBER_MANAGER",
    "MEMBER_REGULAR",
  ],
  CAN_COMPLETE_CALL_REVIEW: ["USER_ADMIN", "AUTH_USER"],
  CAN_READ_CALL: [
    "USER_ADMIN",
    "MEMBER_OWNER",
    "MEMBER_MANAGER",
    "MEMBER_REGULAR",
  ],
  CAN_REQUEST_PROCESS_CALL: ["USER_ADMIN", "MEMBER_OWNER", "MEMBER_MANAGER"],
  CAN_REQUEST_PROCESS_ALL_CALLS: ["USER_ADMIN"],
  CAN_WRITE_CALL: [
    "USER_ADMIN",
    "MEMBER_OWNER",
    "MEMBER_MANAGER",
    "MEMBER_REGULAR",
  ],
  CAN_DELETE_CALL_ISSUE: ["USER_ADMIN", "MEMBER_OWNER", "MEMBER_MANAGER"],
  CAN_WRITE_INVITATION: ["USER_ADMIN", "MEMBER_OWNER", "MEMBER_MANAGER"],
  CAN_READ_INVITATIONS: ["USER_ADMIN", "MEMBER_OWNER", "MEMBER_MANAGER"],
  CAN_UPDATE_NOTIFICATION_HAS_BEEN_READ: ["AUTH_USER"],
  CAN_WRITE_HUBSPOT: ["USER_ADMIN"],
  CAN_WRITE_PROVIDER: ["USER_ADMIN", "MEMBER_OWNER", "MEMBER_MANAGER"],
  CAN_DELETE_NOTIFICATION: ["AUTH_USER"],
  CAN_COPY_SFTP_CONNECTION: ["USER_ADMIN"],
};
