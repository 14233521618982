export const HELP_CENTER_AGENTS = [
  {
    imageUrl: "/assets/help-center-agent-cg.jpg",
    name: "Christian Gehrt",
    title: "Director Customer Success & Project Operations",
    phones: ["+49 30 2178 3906", "+49 175 657 9531"],
    email: "christian.gehrt@snubes.com",
  },
  /*{
    imageUrl: "/assets/help-center-agent-tw.jpg",
    name: "Thomas Wittkopf",
    title: "Director Growth",
    phones: ["+49 30 229 5756 3", "+41 76 268 92 98"],
    email: "thomas.wittkopf@snubes.com",
  },*/
];
